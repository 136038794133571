.bg-main {
  background-image: url("../../../public/images/Container.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.btn-sol{
background-color: black;
color: white;
width: 8rem;
padding: 1rem;
border-top-right-radius: 1.5rem;
border-bottom-right-radius: 1.1rem;
border-bottom-left-radius: 2rem;
border-top-left-radius: .8rem;
}