@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bangers";
}
p{
    font-family: "Bangers";

}